// extracted by mini-css-extract-plugin
export var container = "research-single-module--container--1BBZ6";
export var research = "research-single-module--research--2UeJ3";
export var researchTitle = "research-single-module--researchTitle--1igev";
export var researchImages = "research-single-module--researchImages--2dUlu";
export var researchImageWrapper = "research-single-module--researchImageWrapper--M-iBx";
export var researchImage = "research-single-module--researchImage--k_3YH";
export var overlay = "research-single-module--overlay--tCbjf";
export var contentWrapper = "research-single-module--contentWrapper--2jwzH";
export var content = "research-single-module--content--329cS";
export var close = "research-single-module--close--1swbg";
export var researchAuthor = "research-single-module--researchAuthor--2gzcT";
export var researchYear = "research-single-module--researchYear--2uHql";
export var researchAuthorLink = "research-single-module--researchAuthorLink--1oFiC";
export var researchDescription = "research-single-module--researchDescription--1naDj";
export var researchTags = "research-single-module--researchTags--1_ZI6";
export var researchTag = "research-single-module--researchTag--xcQbL";
export var relativeResearchs = "research-single-module--relativeResearchs--3-F0b";
export var relativeResearchsSubtitle = "research-single-module--relativeResearchsSubtitle--1xj6a";
export var relativeResearchItem = "research-single-module--relativeResearchItem--1Ax-6";
export var relativeResearchDesc = "research-single-module--relativeResearchDesc--2FsQZ";
export var relativeResearchItemImg = "research-single-module--relativeResearchItemImg--EXOCf";
export var relativeResearchName = "research-single-module--relativeResearchName--1fgiD";
export var relativeResearchStudentName = "research-single-module--relativeResearchStudentName--1qE76";
export var relativeResearchFlexWrapper = "research-single-module--relativeResearchFlexWrapper--1bR4i";
export var relativeResearchTags = "research-single-module--relativeResearchTags--w_Kb2";
export var relativeResearchTag = "research-single-module--relativeResearchTag--1ANcP";
export var relativeResearchNotice = "research-single-module--relativeResearchNotice--3j-bb";