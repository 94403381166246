import * as React from 'react'
import { useState } from 'react'
import * as styles from '../components/research-single.module.scss'
import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Cross from '../images/cross.png'
import GlobalWrapper from '../components/GlobalWrapper'
import SEO from '../components/SEO'

const IndexPage = ({ data }) => {
  const [selectedImage, setSelectedImage] = useState()
  const [axisX, setAxisX] = useState()
  const [axisY, setAxisY] = useState()
  const [isModal, setIsModal] = useState(false)
  const settings = {
    arrows: false,
    autoplay: false,
    centerMode: true,
    centerPadding: '0px',
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const singleData = data.single.frontmatter
  const listData = data.list

  const handleClickImage = (image, e) => {
    setSelectedImage(image)
    setAxisX(e.clientX)
    setAxisY(e.clientY)
  }

  const handleMouseUpImage = e => {
    const threshold = 150
    if (e.clientX < axisX + threshold && e.clientX > axisX - threshold) {
      if (e.clientY < axisY + threshold && e.clientY > axisY - threshold) {
        setIsModal(true)
        return
      }
    }
    setSelectedImage(null)
    setIsModal(false)
    return
  }

  const handleClickCloseButton = () => {
    setSelectedImage(null)
    setIsModal(false)
  }

  return (
    <>
      <SEO
        description={singleData.description}
        image={singleData.researchImages[0].childImageSharp.gatsbyImageData}
        title={singleData.title}
      />

      <main className={styles.container}>
        {isModal && (
          <div className={styles.overlay}>
            <div className={styles.contentWrapper}>
              <GatsbyImage
                className={styles.content}
                image={selectedImage?.childImageSharp?.gatsbyImageData}
                alt="研究画像"
              />
              <button onClick={handleClickCloseButton} className={styles.close}>
                <img src={Cross} alt="閉じるボタン" />
              </button>
            </div>
          </div>
        )}
        <GlobalWrapper>
          <div className={styles.research}>
            <h1 className={styles.researchTitle}>{singleData.title}</h1>
            <div className={styles.researchImages}>
              <Slider {...settings}>
                {singleData.researchImages.map((image, index) => {
                  return (
                    <div className={styles.researchImageWrapper} key={index}>
                      <GatsbyImage
                        className={styles.researchImage}
                        image={getImage(image.childImageSharp.gatsbyImageData)}
                        onMouseUp={handleMouseUpImage}
                        onClick={e => handleClickImage(image, e)}
                        alt="研究画像"
                        role="img"
                      />
                    </div>
                  )
                })}
              </Slider>
            </div>
            <p className={styles.researchAuthor}>
              メンバー：
              <Link
                to={'/members/' + singleData.key}
                className={styles.researchAuthorLink}
              >
                {singleData.researchers}
              </Link>
            </p>
            <p className={styles.researchYear}>
              卒業年度：{singleData.graduateYear}年
            </p>
            <p className={styles.researchDescription}>
              {singleData.description}
            </p>
            <div className={styles.researchTags}>
              {singleData.tags.map((tag, index) => {
                return (
                  <p className={styles.researchTag} key={index}>
                    {tag}
                  </p>
                )
              })}
            </div>
          </div>
          <div className={styles.relativeResearchs}>
            <h2 className={styles.relativeResearchsSubtitle}>同期生の研究</h2>
            {listData.edges.length !== 0 ? (
              <>
                {listData.edges.map(item => (
                  <Link
                    className={styles.relativeResearchItem}
                    to={'/researchs/' + item.node.frontmatter.key}
                    key={item.node.frontmatter.key}
                  >
                    <GatsbyImage
                      className={styles.relativeResearchItemImg}
                      image={getImage(
                        item.node.frontmatter.researchImages[0].childImageSharp
                          .gatsbyImageData
                      )}
                      alt="研究のサムネイル"
                    />
                    <div className={styles.relativeResearchDesc}>
                      <p className={styles.relativeResearchName}>
                        {item.node.frontmatter.title}
                      </p>
                      <div className={styles.relativeResearchFlexWrapper}>
                        <p className={styles.relativeResearchStudentName}>
                          {item.node.frontmatter.researchers}
                          <span>
                            （{item.node.frontmatter.graduateYear}年度卒業）
                          </span>
                        </p>
                        <div className={styles.relativeResearchTags}>
                          {item.node.frontmatter.tags.map(tag => (
                            <p className={styles.relativeResearchTag} key={tag}>
                              {tag}
                            </p>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </>
            ) : (
              <p className={styles.relativeResearchNotice}>
                同期生の研究データはありません
              </p>
            )}
          </div>
        </GlobalWrapper>
      </main>
    </>
  )
}

export const query = graphql`
  query ($key: String, $admissionYear: Date) {
    single: markdownRemark(
      fileAbsolutePath: { regex: "/researchs/" }
      frontmatter: { key: { eq: $key } }
    ) {
      frontmatter {
        key
        title
        graduateCourse
        description
        portfolio
        researchers
        tags
        graduateYear
        researchImages {
          childImageSharp {
            gatsbyImageData(width: 700)
          }
        }
      }
    }
    list: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/researchs/" }
        frontmatter: {
          admissionYear: { eq: $admissionYear }
          key: { ne: $key }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            key
            researchers
            graduateCourse
            graduateYear
            admissionYear
            researchImages {
              childImageSharp {
                gatsbyImageData(width: 700)
              }
            }
            tags
          }
        }
      }
    }
  }
`

export default IndexPage
